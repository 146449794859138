import React from 'react';
import styled from "@emotion/styled";
import {Row} from '../../components/base';
import useWindowSize, { getSize } from '../../hooks/useWindowSize';

type PartnerLineProps = {
    children: any,
}
export const PartnerHightlightedLine = ({ children }: PartnerLineProps) => {
    const {type} = useWindowSize()
    const layout = getSize(type,['1fr', '1fr', '1fr 1fr'])
    const position = getSize(type,['start', 'start', 'center'])
    return <PartnerLineRow layout={layout} position={position}>
        {children}
    </PartnerLineRow>
}


const PartnerLineRow = styled(Row)<{layout:string, position:string}>`
    display: grid;
    grid-template-columns: ${({layout}) => layout};
    grid-gap: 20px;
    justify-items: ${({position}) => position};
`