import styled from "@emotion/styled";
import tw from "tailwind.macro";

export const PartnerHightlightedItem = styled.div`
    ${tw`
        text-xs
        font-hairline
        text-lightGray
    `}
    > img {
        ${tw`
            mt-1
        `}
        height: 40px;
    }
`