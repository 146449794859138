import React from 'react';
import styled from "@emotion/styled";
import useWindowSize, { getSize } from '../../hooks/useWindowSize';

type PartnerLineProps = {
    children: any,
}
export const PartnerLine = ({ children }: PartnerLineProps) => {
    const {type} = useWindowSize()
    const layout = getSize(type,['1fr', '1fr', 'repeat(4, 1fr)'])

    return <PartnerLineRow layout={layout} className='py-3'>
        {children}
    </PartnerLineRow>
}


const PartnerLineRow = styled.div<{layout:string}>`
    display: grid;
    grid-template-columns: ${({layout}) => layout};
    grid-gap: 20px;
`