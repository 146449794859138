import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import PageContainer from "../components/page-container"
import SEO from "../components/seo"
import { Title } from "../components/content/title"
import { Text } from "../components/base/typo"
import { SimpleContentLayer } from "../components/contentLayer/contentLayer"
import { SectionParagraph } from "../components/content/paragraph"
import { Container } from "../components/container/container"
import { MenuWrapper } from "../components/container/menuWrapper"

import useWindowSize, { getSize } from "../hooks/useWindowSize"

import { PartnerHightlightedLine } from "../content/partners/partnerHightlightedLine"
import { PartnerHightlightedItem } from "../content/partners/partnerHighlightedItem"
import { PartnerLine } from "../content/partners/partnerLine"
import { PartnerItem } from "../content/partners/partnerItem"
import { Button } from "../components/buttons/buttons"
import ROUTES from "../config/constants/route"
import { formatMarkdownData } from "../helpers/formatGraphqlData"
import { CustomReactMarkdown } from "../components/customReactMarkdown"

export const query = graphql`
  query PartnersPageQuery($locale: String) {
    strapiPartner(locale: { eq: $locale }) {
      id
      title1
      epflLabel
      engagementMigrosLabel
      epflLogo {
        localFile {
          publicURL
        }
        name
      }
      engagementMigrosLogo {
        localFile {
          publicURL
        }
        name
      }
      paragraph1 {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      paragraph2 {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      subTitle1
      coreMuseums {
        country
        name
        link
      }
      title2
      paragraph3
      buttonText
    }
  }
`

const PartnersPage = ({ data, intl }) => {
  const { type } = useWindowSize()
  const layoutPartners = getSize(type, ["default", "default", "large"])

  const {
    title1,
    epflLabel,
    engagementMigrosLabel,
    engagementMigrosLogo,
    epflLogo,
    paragraph1,
    subTitle1,
    paragraph2,
    coreMuseums,
    title2,
    paragraph3,
    buttonText,
  } = data.strapiPartner ?? {}

  return (
    <PageContainer>
      <SEO title="Partners" />
      <MenuWrapper pageName="partners">
        <SimpleContentLayer>
          <Container>
            <Title bold>{title1}</Title>
          </Container>

          <div className="py-5" />

          <Container width="large">
            <PartnerHightlightedLine>
              <a href="https://www.engagement-migros.ch/" target="_blank">
                <PartnerHightlightedItem>
                  <Text size="sm" weight="light">
                    {engagementMigrosLabel}
                  </Text>
                  <img
                    src={engagementMigrosLogo?.localFile?.publicURL}
                    alt={engagementMigrosLogo?.name}
                  />
                </PartnerHightlightedItem>
              </a>
              <a href="https://www.epfl.ch/" target="_blank">
                <PartnerHightlightedItem>
                  <Text size="sm" weight="light">
                    {epflLabel}
                  </Text>
                  <img
                    src={epflLogo?.localFile?.publicURL}
                    alt={epflLogo?.name}
                  />
                </PartnerHightlightedItem>
              </a>
            </PartnerHightlightedLine>
          </Container>

          <div className="py-5" />

          <Container>
            <SectionParagraph>
              <Text size="lg" weight="light">
                <CustomReactMarkdown source={formatMarkdownData(paragraph1)} />
              </Text>
            </SectionParagraph>
          </Container>

          <div className="py-5" />

          <Container>
            <Title bold>{subTitle1}</Title>
            <SectionParagraph>
              <Text size="lg" weight="light">
                <CustomReactMarkdown source={formatMarkdownData(paragraph2)} />
              </Text>
            </SectionParagraph>
          </Container>

          <div className="py-5" />

          <Container width={layoutPartners}>
            <PartnerLine>
              {coreMuseums?.map(({ country, name, link }, i) => (
                <a key={i} href={link} target="_blank">
                  <PartnerItem location={country} museum={name} />
                </a>
              ))}
            </PartnerLine>
          </Container>

          <div className="py-10" />

          <Container>
            <Title bold>{title2}</Title>
            <SectionParagraph>
              <Text size="lg" weight="light">
                {paragraph3}
              </Text>
            </SectionParagraph>
            <div className="py-2" />
            <Button link={ROUTES.CONTACT} text={buttonText} />
          </Container>

          <div className="py-8" />
        </SimpleContentLayer>
      </MenuWrapper>
    </PageContainer>
  )
}

export default injectIntl(PartnersPage)
