import React from 'react';

import {Row, Text} from '../../components/base';
import {Title} from '../../components/content/title'

type PartnerItemProps = {
    location: any,
    museum: any
}
export const PartnerItem = ({ location, museum }: PartnerItemProps) => <div>
    <Row className='my-5'>
        <Text size='sm'>{location}</Text>
    </Row>
    <Title section={false}>{museum}</Title>
</div>